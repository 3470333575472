import $ from 'jquery'
import Tooltip from './tooltip'

/**
 * --------------------------------------------------------------------------
 * Bootstrap (v4.1.1): popover.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * --------------------------------------------------------------------------
 */

