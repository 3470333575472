import $ from 'jquery'
import Popper from 'popper.js'
import Util from './util'

/**
 * --------------------------------------------------------------------------
 * Bootstrap (v4.1.1): tooltip.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * --------------------------------------------------------------------------
 */

const Tooltip = (($) => {
  /**
   * ------------------------------------------------------------------------
   * Constants
   * ------------------------------------------------------------------------
   */

  const NAME               = 'tooltip'
  const VERSION            = '4.1.1'
  const DATA_KEY           = 'bs.tooltip'
  const EVENT_KEY          = `.${DATA_KEY}`
  const JQUERY_NO_CONFLICT = $.fn[NAME]
  const CLASS_PREFIX       = 'bs-tooltip'
  const BSCLS_PREFIX_REGEX = new RegExp(`(^|\\s)${CLASS_PREFIX}\\S+`, 'g')

  const DefaultType = {
    animation           : 'boolean',
    template            : 'string',
    title               : '(string|element|function)',
    trigger             : 'string',
    delay               : '(number|object)',
    html                : 'boolean',
    selector            : '(string|boolean)',
    placement           : '(string|function)',
    offset              : '(number|string)',
    container           : '(string|element|boolean)',
    fallbackPlacement   : '(string|array)',
    boundary            : '(string|element)'
  }

  const AttachmentMap = {
    AUTO   : 'auto',
    TOP    : 'top',
    RIGHT  : 'right',
    BOTTOM : 'bottom',
    LEFT   : 'left'
  }

  const Default = {
    animation           : true,
    template            : '<div class="tooltip" role="tooltip">' +
                        '<div class="arrow"></div>' +
                        '<div class="tooltip-inner"></div></div>',
    trigger             : 'hover focus',
    title               : '',
    delay               : 0,
    html                : false,
    selector            : false,
    placement           : 'top',
    offset              : 0,
    container           : false,
    fallbackPlacement   : 'flip',
    boundary            : 'scrollParent'
  }

  const HoverState = {
    SHOW : 'show',
    OUT  : 'out'
  }

  const Event = {
    HIDE       : `hide${EVENT_KEY}`,
    HIDDEN     : `hidden${EVENT_KEY}`,
    SHOW       : `show${EVENT_KEY}`,
    SHOWN      : `shown${EVENT_KEY}`,
    INSERTED   : `inserted${EVENT_KEY}`,
    CLICK      : `click${EVENT_KEY}`,
    FOCUSIN    : `focusin${EVENT_KEY}`,
    FOCUSOUT   : `focusout${EVENT_KEY}`,
    MOUSEENTER : `mouseenter${EVENT_KEY}`,
    MOUSELEAVE : `mouseleave${EVENT_KEY}`
  }

  const ClassName = {
    FADE : 'fade',
    SHOW : 'show'
  }

  const Selector = {
    TOOLTIP       : '.tooltip',
    TOOLTIP_INNER : '.tooltip-inner',
    ARROW         : '.arrow'
  }

  const Trigger = {
    HOVER  : 'hover',
    FOCUS  : 'focus',
    CLICK  : 'click',
    MANUAL : 'manual'
  }


  /**
   * ------------------------------------------------------------------------
   * Class Definition
   * ------------------------------------------------------------------------
   */

 